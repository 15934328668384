import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const NotFound =  () => {
  return (
    <Layout>
      <Head title={'Not Found'}/>
      <h1>Page non existante</h1>
      <p>Désolé, vous avez dû entrer une url erronée dans la barre d'adresse.</p>
      <p><Link to={'/'}>Home Page</Link></p>
    </Layout>
  )
} 

export default NotFound